<template>
  <Layout>
    <div class="app-couponlist">
      <div class="tool">
        <a-form layout="inline">
          <a-button @click="add" type="primary">
            <a-icon type="file-add" /> 创建优惠券
          </a-button>
        </a-form>

        <a-form layout="inline" :form="form">
          <a-form-model-item label="劵状态">
            <a-radio-group
              @change="handleSearch"
              v-model="status"
              button-style="solid"
            >
              <a-radio-button :value="0">全部</a-radio-button>
              <a-radio-button :value="1">未生效</a-radio-button>
              <a-radio-button :value="2">生效中</a-radio-button>
              <a-radio-button :value="200">已过期/作废</a-radio-button>
              <a-radio-button :value="5">已删除</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-form>
      </div>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          @change="handleTableChange"
        >
          <span slot="stat" slot-scope="_, record">
            <a-tag>发放数</a-tag>{{ record.total_num }} <br />
            <a-tag>领取数</a-tag>{{ record.receive_num }} <br />
            <a-tag>使用数</a-tag>{{ record.used_num }}
          </span>
          <span slot="action" slot-scope="_, record">
            <template v-if="record.status === 1">
              <router-link
                :to="{ name: 'CouponEdit', query: { id: record.id } }"
              >
                编辑
              </router-link>
              <a-divider type="vertical" />
            </template>

            <template v-if="record.status === 2">
              <a @click="share(record)">分享</a>
              <a-divider type="vertical" />
            </template>

            <a v-if="record.status !== 5" @click="updateStatus(record)">{{
              statusBtn(record)
            }}</a>
          </span>
        </a-table>
      </div>

      <!-- 分享二维码 -->
      <a-modal
        :destroyOnClose="true"
        :footer="null"
        :visible="shareDialogVisible"
        @cancel="shareDialogVisible = false"
        class="share-dialog-wrap"
      >
        <div class="title">
          右键保存小程序码即可分享
        </div>
        <img :src="shareInfo.qrcode" class="qrcode" />
        <div class="row">
          <span class="label">AppId：</span>
          <a-input disabled :value="store.id" />
          <a-button @click="copyText(store.id)">复制</a-button>
        </div>
        <div class="row">
          <span class="label">路径：</span>
          <a-input disabled :value="shareInfo.path" />
          <a-button @click="copyText(shareInfo.path)">复制</a-button>
        </div>
        <div class="row">
          <a-icon type="info-circle" />
          提示：请先装修首页并发布，否则会显示「页面不存在」的错误
        </div>
      </a-modal>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import { mapState } from "vuex";

const CouponSvc = require("@/service/coupon");

import wxacodeMixin from "@/mixins/wxacode";

export default {
  name: "CouponList",

  data() {
    return {
      status: 0,
      form: this.$form.createForm(this, { name: "form_search" }),

      list: [],
      filters: {},
      pagination: {
        showTotal: total => `共 ${total} 条记录`
      },
      loading: false
    };
  },

  mixins: [wxacodeMixin],

  computed: {
    ...mapState({
      store: state => state.store
    }),

    columns() {
      const { filters } = this;

      return [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          width: 120
        },
        {
          title: "优惠券名称",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "券类型",
          dataIndex: "typeStr",
          key: "typeStr",
          width: 160
        },
        {
          title: "券有效期",
          dataIndex: "validStr",
          key: "validStr",
          width: 140
        },
        {
          title: "推广渠道",
          dataIndex: "channelStr",
          key: "channelStr",
          width: 100
        },
        {
          title: "数据",
          dataIndex: "status",
          key: "status",
          width: 140,
          scopedSlots: { customRender: "stat" }
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "statusStr",
          key: "statusStr",
          width: 100,
          filteredValue: filters.status || ""
        },
        {
          title: "操作",
          key: "action",
          width: 140,
          scopedSlots: { customRender: "action" }
        }
      ];
    }
  },

  methods: {
    statusBtn(item) {
      switch (item.status) {
        case 1:
          return "生效";
        case 2:
          return "作废";
        case 3:
        case 4:
          return "删除";
        default:
          return "";
      }
    },

    add() {
      this.$router.push({ path: "/sale/coupon/edit" });
    },

    async share(item) {
      const path =
        item.promote_type === 3
          ? `plugin-private://wx34345ae5855f892d/pages/bindgroup/bindgroup?action=2&couponId=${item.id}` // 分享券
          : `plugin-private://wxd14f33852509c129/pages/couponDetail/index?couponId=${item.id}`; // // 普通劵

      await this.sharePath(path);
    },

    async updateStatus(record) {
      try {
        const { id, status } = record;

        if (status >= 5) return;

        const NEXT_STATUS = {
          1: 2,
          2: 4,
          3: 5,
          4: 5
        };

        await CouponSvc.updateStatus(id, NEXT_STATUS[status]);

        await this.fetch(this.filters);
      } catch (err) {
        console.error(err);
        this.$message.error("切换状态失败，错误：" + err.message);
      }
    },

    async handleSearch() {
      let { status } = this;
      status = status || 0;
      this.filters = { ...this.filters, status };

      await this.fetch(this.filters);
    },

    async handleTableChange(pagination, filters) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.filters = filters;

      const { pageSize, current: page } = pagination;
      await this.fetch({
        pageSize,
        page,
        ...filters
      });
    },

    async fetch(params = {}) {
      this.loading = true;

      try {
        const rows = await CouponSvc.list({
          page: 1,
          pageSize: 1000,
          ...params
        });

        const cnt = rows.length;

        const pagination = {
          ...this.pagination,
          total: cnt,
          current: 1
        };
        this.list = rows;
        this.pagination = pagination;

        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    await this.handleSearch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-couponlist {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }
  .table-wrap {
    background: #fff;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #fff;
    }

    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  }

  .product-img {
    width: 60px;
    max-height: 60px;
  }
}
</style>
