const UserSvc = require("@/service/user");
import copy from "copy-to-clipboard";

/**
 * 分享小程序码
 */
export default {
  data() {
    return {
      shareDialogVisible: false,
      shareInfo: {
        qrcode: "",
        path: ""
      }
    };
  },

  methods: {
    async sharePath(path) {
      try {
        // 获取小程序码
        const res = await UserSvc.getWXACode(path);
        const qrcode = "data:image/png;base64," + res.b64;

        this.shareInfo = {
          path,
          qrcode
        };

        this.shareDialogVisible = true;
      } catch (err) {
        console.error(err);
        this.$message.error("获取小程序码失败，错误：" + err.message);
      }
    },

    copyText(txt) {
      copy(txt);
      this.$message.info("内容已复制");
    }
  }
};
