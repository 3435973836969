import request from "./request";

/**
 * 优惠券
 */

/**
 * 查询优惠券
 */
export const list = data => request.get("/cp/", { params: data });

/**
 * 查询优惠券详情
 */
export const detail = id => request.get("/cp/detail", { params: { id } });

/**
 * 创建/编辑优惠券
 */
export const createOrUpdate = data => request.post("/cp/", data);

/**
 * 切换状态
 */
export const updateStatus = (id, status) =>
  request.post("/cp/status", { id, status });
